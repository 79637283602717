import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Front Rack Lunges 3×12/leg`}</p>
    <p>{`DB RDL’s 3×12`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`3-DB Thrusters (40/25’s)(RX+ 50/35’s)`}</p>
    <p>{`3-Pullups (RX+ CTB Pullups)`}</p>
    <p>{`6-DB Thrusters`}</p>
    <p>{`6-Pullups`}</p>
    <p>{`9-DB Thrusters`}</p>
    <p>{`9-Pullups`}</p>
    <p>{`etc,`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility class will be today from 11:30-12:00.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule starts this week!  Evening CrossFit classes
are now offered Monday-Friday at 4:00, 5:00 & 6:00pm.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new Ville Bootcamp starts today!  These classes will be
Tues-Friday at 9:30am.  The rest of this month is free for non members
so spread the word if you know of anyone that may want to try it out.
This class is included in your unlimited membership. `}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      